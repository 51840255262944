jQuery(document).ready(function($) {
    let imgTakeoverCols = $('.image-takeover-wrapper .column')
    imgTakeoverCols.hover(function(){
        //When mouse is hovering...
        let thisHoverURL = $(this).find('.bg-img-hover').data('src');
        $('.image-takeover-wrapper .bg-img-hover').addClass('hover-state');
        imgTakeoverCols.addClass('other-col-hovering');
        $(this).removeClass('other-col-hovering');
        $('.image-takeover-wrapper .bg-img-hover').css('background-image', 'url('+thisHoverURL+')');
    },
    function(){
        //When mouse stops hovering...
        imgTakeoverCols.removeClass('other-col-hovering');
        $('.image-takeover-wrapper .bg-img-hover').removeClass('hover-state');
    });

    //Select all columns that actually link somewhere so we can filter out the ones that don't
    var slidesWithLinks = $('.image-takeover-wrapper .column a').parent();

    //SLIDER FOR MEDIUM AND DOWN
    if($(window).width() < 1025){
        $('.image-takeover-wrapper .all-columns').slick({
            arrows: false,
            dots: true,
            slidesToShow: 2,
            responsive: [
                {
                  breakpoint: 600,
                  settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 1,
                  }
                }
            ]
        });
            
        //Filter the slider to only show columns that contain links
        $('.image-takeover-wrapper .all-columns').slick('slickFilter', slidesWithLinks);

    }
    $(window).resize(function(){
        //When the window is resized and ends up being less than 1025 pixels initialize a slider
        if($(window).width() < 1025){
            $('.image-takeover-wrapper .all-columns').slick({
                arrows: false,
                dots: true,
                slidesToShow: 2,
                responsive: [
                    {
                      breakpoint: 600,
                      settings: {
                        arrows: false,
                        dots: true,
                        slidesToShow: 1,
                      }
                    }
                ]
            });
            
            //Filter the slider to only show columns that contain links
            $('.image-takeover-wrapper .all-columns').slick('slickFilter', slidesWithLinks);
        }else{
            //When the window is resized and ends up being MORE than 1025 destroy the slider
            $('.image-takeover-wrapper .all-columns').slick('unslick');
        }
    });
})